<template>
    <div>
        <!-- STICK-SHIFT -->
        <v-dialog v-model="stickShiftDialog"
                  dark
                  scrollable
                  persistent
                  max-width="800">
            <v-card class="shift-background">

                <v-card-title class="text-h5">
                    Stick-Shift
                </v-card-title>

                <v-card-text>
                    Upload any transactions that were not imported into your tax file.

                    <b-button v-b-toggle.sidebar-stickShift class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                        <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                    </b-button>

                    <h6 class="red--text mt-4">Uncovered Transactions</h6>
                    <b-table ref="tblUncovered"
                             id="tblUncovered"
                             :fields="uncoveredTransactionFields"
                             :items="uncoveredTransactions"
                             sticky-header
                             small
                             table-variant="primary"
                             class="vertical-align: middle"
                             stacked="md"
                             :bordered="true">
                    </b-table>

                    <h6 class="blue--text mt-6">Manual Transactions</h6>
                    <v-btn class="btn-sm mb-3 white--text" small style="font-size:smaller;" color="blue darken-1" @click="showManualTransaction"> Add Manual Transaction </v-btn>

                    <b-table ref="tblStickShift"
                             id="tblStickShift"
                             :fields="stickShiftFields"
                             :items="stickShiftRecords"
                             sticky-header
                             small
                             table-variant="primary"
                             class="vertical-align: middle"
                             stacked="md"
                             :bordered="true">
                        <template v-slot:cell(edit)="data">
                            <b-button class="btn-success" @click="editRecord(data.item.id)" size="sm" style="width:30px;">
                                <font-awesome-icon :icon="['fas', 'edit']" color="white" />
                            </b-button>
                        </template>
                        <template v-slot:cell(id)="{value}">
                            <b-button class="btn-danger" @click="deleteRecord(value)" size="sm">
                                <font-awesome-icon :icon="['fas', 'trash-alt']" color="white" />
                            </b-button>
                        </template>
                    </b-table>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 float-left" color="red darken-1"
                           text
                           @click="closeDialog()">
                        Cancel
                    </v-btn>
                    <v-btn class="mt-4 float-right" color="blue"
                           text
                           @click="reprocessTaxFile()">
                        Re-process my tax file
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- STICK-SHIFT ADD/EDIT -->
        <v-dialog v-model="stickShiftRecordDialog"
                  persistent
                  max-width="600px">
            <v-card dark>
                <v-card-title>
                    <span class="text-h5">Manual Transaction</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-simple-table v-if="currentStickShiftRecord">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Date</td>
                                            <td>
                                                <!-- Date Menu-->
                                                <v-menu ref="date_menu"
                                                        dark
                                                        v-model="dateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="currentStickShiftRecord.date"
                                                        transition="scale-transition"
                                                        offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="currentStickShiftRecord.date"
                                                                      prepend-icon="mdi-calendar"
                                                                      readonly
                                                                      v-bind="attrs"
                                                                      v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="currentStickShiftRecord.date"
                                                                   no-title
                                                                   scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text
                                                               color="primary"
                                                               @click="dateMenu = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text
                                                               color="primary"
                                                               @click="$refs.date_menu.save(currentStickShiftRecord.date)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Time</td>
                                            <td>
                                                <!-- Time Menu-->
                                                <v-menu ref="time_menu"
                                                        dark
                                                        v-model="timeMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="currentStickShiftRecord.time"
                                                        transition="scale-transition"
                                                        offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="currentStickShiftRecord.time"
                                                                      prepend-icon="mdi-clock-time-four-outline"
                                                                      readonly
                                                                      v-bind="attrs"
                                                                      v-on="on"></v-text-field>
                                                    </template>
                                                    <v-time-picker v-if="timeMenu"
                                                                   v-model="currentStickShiftRecord.time"
                                                                   use-seconds
                                                                   full-width>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text
                                                               color="primary"
                                                               @click="timeMenu = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text
                                                               color="primary"
                                                               @click="$refs.time_menu.save(currentStickShiftRecord.time)">
                                                            OK
                                                        </v-btn>
                                                    </v-time-picker>
                                                </v-menu>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td style="height:60px;">
                                                <v-menu offset-y :rules="[rules.required]">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="dark"
                                                               dark
                                                               v-bind="attrs"
                                                               v-on="on">
                                                            <span v-if="currentStickShiftRecord.type === null"> Select Type</span>
                                                            <span v-if="currentStickShiftRecord.type !== null">{{currentStickShiftRecord.type}}</span>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item-title class="btn" @click="selectType('Buy')">Buy</v-list-item-title>
                                                        <br />
                                                        <v-list-item-title class="btn" @click="selectType('Sell')">Sell</v-list-item-title>
                                                        <br />
                                                        <v-list-item-title class="btn" @click="selectType('Send')">Send</v-list-item-title>
                                                        <br />
                                                        <v-list-item-title class="btn" @click="selectType('Receive')">Receive</v-list-item-title>
                                                        <br />
                                                        <v-list-item-title class="btn" @click="selectType('Rewards/Interest')">Rewards/Interest</v-list-item-title>
                                                        <br />
                                                        <v-list-item-title class="btn" @click="selectType('Convert')">Convert</v-list-item-title>
                                                    </v-list>
                                                </v-menu>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type !== 'Convert'">
                                            <td>Asset</td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              v-model="currentStickShiftRecord.asset"
                                                              placeholder="Asset"
                                                              class="pl-2"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type !== 'Convert'">
                                            <td>Quantity</td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtQuantity"
                                                              v-model="currentStickShiftRecord.quantity"
                                                              placeholder="Quantity"
                                                              class="pl-2"
                                                              :rules="[rules.number]"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Spot Price
                                            </td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtSpotPrice"
                                                              v-model="currentStickShiftRecord.spotPrice"
                                                              placeholder="Spot Price"
                                                              class="pl-2"
                                                              :rules="[rules.number]"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type === 'Convert'">
                                            <td>
                                                Convert From Asset
                                            </td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtConvertFromAsset"
                                                              v-model="currentStickShiftRecord.convertFromAsset"
                                                              placeholder="Convert From Asset"
                                                              class="pl-2"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type === 'Convert'">
                                            <td>
                                                Convert From Quantity
                                            </td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtConvertFromQuantity"
                                                              v-model="currentStickShiftRecord.convertFromQuantity"
                                                              placeholder="Convert From Quantity"
                                                              class="pl-2"
                                                              :rules="[rules.number]"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type === 'Convert'">
                                            <td>
                                                Convert To Asset
                                            </td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtConvertToAsset"
                                                              v-model="currentStickShiftRecord.convertToAsset"
                                                              placeholder="Convert To Asset"
                                                              class="pl-2"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                        <tr v-if="currentStickShiftRecord.type === 'Convert'">
                                            <td>
                                                Convert To Quantity
                                            </td>
                                            <td style="height:60px;">
                                                <v-text-field light
                                                              ref="txtConvertToQuantity"
                                                              v-model="currentStickShiftRecord.convertToQuantity"
                                                              placeholder="Convert To Quantity"
                                                              class="pl-2"
                                                              :rules="[rules.number]"
                                                              style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                              dense>
                                                </v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-6 mr-6"
                           text
                           color="red"
                           @click="cancelManualTransaction()">
                        Cancel
                    </v-btn>
                    <v-btn class="mt-6"
                           text
                           color="green"
                           @click="addEditStickShiftRecord()">
                        <span v-if="this.currentStickShiftRecord === null">Add</span>
                        <span v-if="this.currentStickShiftRecord !== null && this.currentStickShiftRecord.method === 'add'">Add</span>
                        <span v-if="this.currentStickShiftRecord !== null && this.currentStickShiftRecord.method === 'edit'">Update</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- SIDE BARS -->
        <div>
            <b-sidebar id="sidebar-stickShift" title="Stick-Shift" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'screwdriver']" class="fa-3x" color="var(--var-theme-color)" />
                        You are going to love this.  Stick-Shift is CryptoTaxSpace’s way of allowing you to cover a transaction that does not have enough crypto in a previous buy or transfer to cover the sell.  At times, you may have transferred cryptocurrency into an exchange from another exchange.  When you do this the exchange may not include that in your tax file.  When you sell it though we need to know the spot price (price you paid for it originally) so we know your gain/loss.  Think of it as a way to correct your exchange’s tax file.
                    </p>
                    <br />
                    <p>
                        Here is an example:  Let’s say Joel bought 1000 Doge on Binance.US and transferred the coins to Coinbase.  Time goes by and Joel ends up selling 500 of the DOGE for huge profit on the way to the moon.  Joel gets his tax file from Coinbase at the end of year and is doing his taxes.  Joel uses CryptoTaxSpace to get his Turbotax crypto file ready for import.  He imports his Coinbase file into CryptoTaxSpace and we find that the DOGE Joel sold only was listed in the Coinbase file on the sell.  Coinbase did not include the original 1000 DOGE Joel transferred.
                    </p>
                    <br />
                    <p>
                        This is where Stick-Shift comes in.  You know you transferred it from Binance.US.  Click Stick-Shift and enter the original 1000 and what you originally bought it at.  Click reprocess on CryptoTaxSpace and poof, done.  Download your file and it is ready to go for TurboTax.
                    </p>
                    <br />
                    <b-button v-b-toggle.sidebar-stickShift class="mt-5" style="background-color:var(--var-theme-color);;"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>
        </div>
    </div>
</template>

<script>

    import { cloneDeep } from 'lodash';
    import { fileService } from '../services/file-service';
    import { notificationHooks } from '../shared/vue-mixin';

    export default {
        name: 'StickShift',

        /* COMPONENT DATA  */
        data() {
            return {
                valid: true,
                dateMenu: false,
                timeMenu: false,
                invalidType: false,
                isUploading: false,
                rawTaxFile: null,
                stickShiftDialog: false,
                stickShiftRecordDialog: false,
                currentStickShiftRecord: null,
                stickShiftRecords: [],
                uncoveredTransactions: [],
                stickShiftFields: [
                    { key: 'date', label: "Date", sortable: false, class: 'centeredCell' },
                    { key: 'time', label: "Time", sortable: false, class: 'centeredCell' },
                    { key: 'asset', label: "Asset", sortable: false, class: 'centeredCell' },
                    { key: 'typeText', label: "Type", sortable: false, class: 'centeredCell' },
                    { key: 'quantity', label: "Quantity", sortable: false, class: 'centeredCell' },
                    { key: 'spotPrice', label: "Spot Price", sortable: false, class: 'centeredCell' },
                    { key: 'edit', label: "Edit", sortable: false, class: 'alignedCell' },
                    { key: 'id', label: "X", sortable: false, class: 'alignedCell' },
                ],
                uncoveredTransactionFields: [
                    { key: 'timestamp', label: "Date", sortable: false, class: 'centeredCell' },
                    { key: 'asset', label: "Asset", sortable: false, class: 'centeredCell' },
                    { key: 'transactionType', label: "Type", sortable: false, class: 'centeredCell' },
                    { key: 'quantity', label: "Quantity", sortable: false, class: 'centeredCell' },
                    { key: 'price', label: "Price", sortable: false, class: 'centeredCell' }
                ],
                rules: {
                    number: value => {
                        const pattern = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$|^0$/
                        return pattern.test(value) || 'Invalid number'
                    }
                }
            };
        },

        /* PROPS */
        props: {
            UploadId: {
                type: String,
                default: () => '',
            },
            TaxYear: {
                type: String,
                default: () => '',
            },
            Exchange: {
                type: String,
                default: () => '',
            },
            TaxMethod: {
                type: String,
                default: () => '',
            },
            ManualExchange: {
                type: String,
                default: () => '',
            },
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* COMPONENT METHODS  */
        methods: {

            /* PUBLIC METHODS  */
            ShowModal(rawTaxFile, stickShiftRecords, uncoveredTransactions) {
                this.stickShiftDialog = true;
                this.stickShiftRecords = stickShiftRecords;
                this.uncoveredTransactions = uncoveredTransactions;
                this.rawTaxFile = rawTaxFile;
            },

            /* PRIVATE METHODS */
            closeDialog() {
                this.$emit('modalClosed');
                this.stickShiftDialog = false;
            },

            showManualTransaction() {
                this.stickShiftRecordDialog = true;
                var createRecord = {
                    id: Date.now(),
                    method: "add",
                    date: null,
                    time: "00:00:00",
                    asset: null,
                    type: null,
                    typeText: null,
                    quantity: null,
                    spotPrice: null,
                    convertFromAsset: null,
                    convertToAsset: null,
                    convertFromQuantity: null,
                    convertToQuantity: null,
                    notes: null
                };
                this.currentStickShiftRecord = createRecord;
            },

            addEditStickShiftRecord() {

                var hasError = false;
               
                if (this.currentStickShiftRecord.date === null)
                    hasError = true;

                if (this.currentStickShiftRecord.time === null)
                    hasError = true;

                if (this.currentStickShiftRecord.type === null)
                    hasError = true;

                if (isNaN(this.currentStickShiftRecord.spotPrice) || this.currentStickShiftRecord.spotPrice === "")
                    hasError = true;

                if (this.currentStickShiftRecord.type !== "Convert") {

                    this.currentStickShiftRecord.asset = this.currentStickShiftRecord.asset.toUpperCase();

                    if (this.currentStickShiftRecord.asset === null || this.currentStickShiftRecord.asset === "")
                        hasError = true;

                    if (isNaN(this.currentStickShiftRecord.quantity) || this.currentStickShiftRecord.quantity === "")
                        hasError = true;  
                }
                else {

                    this.currentStickShiftRecord.convertFromAsset = this.currentStickShiftRecord.convertFromAsset.toUpperCase();
                    this.currentStickShiftRecord.convertToAsset = this.currentStickShiftRecord.convertToAsset.toUpperCase();

                    if (this.currentStickShiftRecord.convertFromAsset === null || this.currentStickShiftRecord.convertFromAsset === "")
                        hasError = true;

                    if (isNaN(this.currentStickShiftRecord.convertFromQuantity) || this.currentStickShiftRecord.convertFromQuantity === "")
                        hasError = true;

                    if (this.currentStickShiftRecord.convertToAsset === null || this.currentStickShiftRecord.convertToAsset === "")
                        hasError = true;

                    if (isNaN(this.currentStickShiftRecord.convertToQuantity) || this.currentStickShiftRecord.convertToQuantity === "")
                        hasError = true;
                }

                if (!hasError) {

                    if (this.currentStickShiftRecord.type !== "Convert") {
                        var addEditStickShiftRecord = {
                            id: this.currentStickShiftRecord.id,
                            date: this.currentStickShiftRecord.date,
                            time: this.currentStickShiftRecord.time,
                            asset: this.currentStickShiftRecord.asset,
                            type: this.currentStickShiftRecord.type,
                            typeText: this.currentStickShiftRecord.type,
                            quantity: this.currentStickShiftRecord.quantity,
                            spotPrice: this.currentStickShiftRecord.spotPrice,
                            convertFromAsset: null,
                            convertToAsset: null,
                            convertFromQuantity: null,
                            convertToQuantity: null,
                            notes: null
                        };
                        if (this.currentStickShiftRecord.method === "add") {
                            this.stickShiftRecords.push(addEditStickShiftRecord);
                        }
                        else {
                            //Update existing record
                            var itemIndex = this.stickShiftRecords.findIndex(x => x.id === addEditStickShiftRecord.id);
                            this.stickShiftRecords[itemIndex] = addEditStickShiftRecord;
                            this.$refs.tblStickShift.refresh();
                        }             
                    }
                    else {
                        var addEditStickShiftRecord = {
                            id: this.currentStickShiftRecord.id,
                            date: this.currentStickShiftRecord.date,
                            time: this.currentStickShiftRecord.time,
                            asset: this.currentStickShiftRecord.convertFromAsset,
                            type: this.currentStickShiftRecord.type,
                            typeText: this.currentStickShiftRecord.type + " to " + this.currentStickShiftRecord.convertToAsset,
                            quantity: this.currentStickShiftRecord.convertFromQuantity,
                            spotPrice: this.currentStickShiftRecord.spotPrice,
                            convertFromAsset: this.currentStickShiftRecord.convertFromAsset,
                            convertToAsset: this.currentStickShiftRecord.convertToAsset,
                            convertFromQuantity: this.currentStickShiftRecord.convertFromQuantity,
                            convertToQuantity: this.currentStickShiftRecord.convertToQuantity,
                            notes: "Converted " + this.currentStickShiftRecord.convertFromQuantity + " " + this.currentStickShiftRecord.convertFromAsset + " to " + this.currentStickShiftRecord.convertToQuantity + " " + this.currentStickShiftRecord.convertToAsset,
                        };
                        if (this.currentStickShiftRecord.method === "add") {
                            this.stickShiftRecords.push(addEditStickShiftRecord);
                        }
                        else {
                            //Update existing record
                            var itemIndex = this.stickShiftRecords.findIndex(x => x.id === addEditStickShiftRecord.id);
                            this.stickShiftRecords[itemIndex] = addEditStickShiftRecord;
                            this.$refs.tblStickShift.refresh();
                        }
                    }
                    this.stickShiftRecordDialog = false;
                    this.$refs.form.reset();
                }
                else {
                    this.showErrorMessage("CryptoTaxSpace", "All fields require valid data.");
                }
            } ,

            editRecord(id) {
                var editRecord = this.stickShiftRecords.find(x => x.id === id);
                editRecord = cloneDeep(editRecord);
                editRecord.method = "edit";
                this.currentStickShiftRecord = editRecord;
                this.stickShiftRecordDialog = true;
            },

            deleteRecord(id) {
                this.stickShiftRecords = this.stickShiftRecords.filter(x => x.id !== id);
            },

            cancelManualTransaction() {
                this.stickShiftRecordDialog = false;
                this.$refs.form.reset();
            },

            selectType(type) {
                this.currentStickShiftRecord.type = type;
            },

            async reprocessTaxFile() {

                var exchangeMethod = this.Exchange;
                if (exchangeMethod === "Enter Transactions Manually")
                    exchangeMethod = "MANUAL";

                var uploadArgs =
                {
                    fileContent: this.rawTaxFile,
                    taxYear: this.TaxYear,
                    taxMethod: this.TaxMethod,
                    exchange: exchangeMethod,
                    manualTransactions: this.stickShiftRecords,
                    manualExchange: this.ManualExchange,
                    uploadId: this.UploadId
                };

                this.uploadResult = await fileService.uploadFileToServer(this, uploadArgs);
                this.isUploading = false;
                if (this.uploadResult.success) {
                    this.$emit('processSuccess', this.uploadResult.value, this.stickShiftRecords);
                    this.$emit('modalClosed');
                    this.stickShiftDialog = false;
                }
                else {
                    this.$emit('uploadFail', this.uploadResult.error);
                }
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {
        }

    };
</script>

<style scoped>

    /deep/ .centeredCell {
        vertical-align: middle !important;
    }

    /deep/ .alignedCell {
        vertical-align: middle !important;
        text-align: center !important;
    }

    /deep/ .b-sidebar-body {
        /* Set help side bar color  here */
        background-color: #424752;
        color: white;
    }

    .shift-background {
        background-image: url("../assets/shift-stick.png");
        height: 700px; /* You must set a specified height */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }

    .cancel-button {
        background-color: white !important;
        color: black;
    }
</style>