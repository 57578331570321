// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/shift-stick.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-08743dda] .alignedCell,[data-v-08743dda] .centeredCell{vertical-align:middle!important}[data-v-08743dda] .alignedCell{text-align:center!important}[data-v-08743dda] .b-sidebar-body{background-color:#424752;color:#fff}.shift-background[data-v-08743dda]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:700px;background-position:50%;background-repeat:no-repeat;background-size:cover}.cancel-button[data-v-08743dda]{background-color:#fff!important;color:#000}", ""]);
// Exports
module.exports = exports;
