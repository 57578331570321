import { API } from '../shared/config';
import { HTTP_POST } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const uploadFileToServer = async function (vm, fileArgs) {

    try {

        if (!serviceHelper.validateToken()) {
            return { success: false, value: null, error: "Session Expired" };
        }

        const response = await serviceHelper.callApi(`${API}/FileUpload`, HTTP_POST, fileArgs);

        return response;

    } catch (error) {
        return { success: false, value: null, error: error };
    }
}

export const fileService = {
  uploadFileToServer
};